@import 'src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.tabsHeader {
  white-space: nowrap;
  box-shadow: 0px 1px 0px rgba(30, 39, 50, 0.12), inset 0px -1px 0px rgba(30, 39, 50, 0.12);

  > :first-child {
    span {
      margin-left: 15px;
    }
  }

  @media (max-width: $tablet-small) {
    overflow-x: auto;
    > :first-child {
      span {
        margin-left: 0px;
      }
    }
  }
}

.logout {
  @media (max-width: $tablet-small) {
    display: none;
  }
}

.body {
  background: $background-dark;
  min-height: calc(100vh - 255px);
  width: 100%;
  padding: 20px 40px;
  @media (max-width: $tablet-small) {
    padding: 20px;
  }
}
