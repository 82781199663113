@import '/src/style/breakpoints.scss';

.title {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.header {
  @media (min-width: $tablet-small) {
    padding: 20px 40px !important;
  }
}

.icon {
  margin-left: 8px;
}

.menuWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}
