@import '/src/style/breakpoints.scss';

.form {
  > :not(:first-child) {
    margin-top: 12px;
  }
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > div {
    width: 100%;
  }
}

.title {
  margin-left: 15px;
}

.wrapped {
  display: flex;
  align-items: center;
}

.type {
  flex-grow: 1;
  flex-basis: 70%;
  margin-right: 20px;
  transform: translateY(-2px);
  z-index: 99999;
}

.format {
  flex-basis: 25%;
}

@media (max-width: $tablet-small) {
  .type {
    flex-basis: 100%;
    margin-right: 0px;
  }

  .format {
    flex-basis: 100%;
  }
}

.modal {
  top: 0;
}

.icon {
  font-size: 40px;
}

.radio {
  justify-self: flex-end;
}

.notesPredict {
  display: grid;
  grid-template-columns: auto 42px;
  column-gap: 10px;
  align-items: center;
}
