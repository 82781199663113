@import '/src/style/breakpoints.scss';

.body {
  padding: 20px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;

  section {
    max-width: 800px;
  }

  @media (min-width: $tablet-small) {
    section {
      max-width: 50vw;
    }
  }

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
    padding: 20px 20px;

    .controlColl {
      grid-row-start: 1;
    }
    section {
      max-width: auto;
    }
  }
}
