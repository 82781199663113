@import '/src/style/breakpoints.scss';

.modalFooter {
  width: 100%;

  @media (min-width: $tablet-small) {
    display: flex;
  
    // radio
    >:nth-child(1) {
      margin-right: auto;
    }
  }

  @media (max-width: $tablet-small) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 10px;

    // radio
    >:nth-child(1) {
      margin-left: auto;
      grid-column: 1/3;
    }
  }
}
