@import '/src/style/breakpoints.scss';

.card {
  header {
    max-height: 56px;
  }
}

.copyBtn {
  align-self: flex-start;
  transform: translateY(-10px);
}

.inputLayout {
  display: grid;
  row-gap: 10px;
}

.companyRow {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;


  @media (min-width: $tablet-small) {
    grid-template-columns: 4fr 3fr;
    column-gap: 10px;
  }
}

.hidden {
  display: none !important;
}
