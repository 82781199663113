@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;

  header {
    justify-content: center;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
  }

  small {
    width: 90%;
    color: rgba($dark, 0.6);
    
    @media (max-width: $tablet-small) {
      margin-top: 20px;
    }
  }

  a {
    margin-right: 5px;
    color: $info;
    &:hover {
      cursor: pointer;
    }
  }
}

.cardBody {

}
