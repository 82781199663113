@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.card {
  header {
    height: 57px;
    align-items: center;
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.timeline {
  margin-left: 20px !important;
}

.checkbox {
  flex-direction: row-reverse;
  margin-right: 12px;
}
