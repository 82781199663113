@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.dueDate {
  display: grid;
  grid-template-columns: 50px auto;
  background-color: mix($attention, #fff, 12%);
  border-radius: 8px;
  height: 40px;
  padding: 8px 11px;

  box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.12), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
    0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.12);

  .icon {
    padding-right: 5px;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($dark, 0.4);
    border-right: 2px solid rgba($attention, 0.2);
    color: rgba($attention, 0.8) !important;

    // @media (max-width: $tablet-small) {
    //   display: none;
    // }
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-left: solid 2px rgba($attention, 0.2);
    line-height: 24px;
    font-size: 16px;
    // padding: 0 11px;

    .innerTitle {
      color: rgba($dark, 0.8);
    }

    .date {
      color: $dark;
      font-weight: 500;
    }
  }
}
