@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 30px auto;
  padding: 10px;
  align-items: flex-start;
  background-color: rgba($dark, .05);
  color: rgba($dark, .8) !important;
  border-radius: 16px;
  column-gap: 10px;

  span {
    font-size: 24px;
  }

  @media (max-width: $tablet-small) {
    grid-template-columns: auto;
    span {
      display: none;
    }
  }
}
