@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-top: auto;
  margin-bottom: 10px;
  padding: 20px 20px;

  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;

  @media (min-width: $tablet-small) {
    padding: 20px 40px !important;
  }

  .privacyWrapper {
    display: flex;
    column-gap: 12px;
    overflow: hidden;

    @media (max-width: $tablet-small) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 5px;

      a {
        padding-left: 0;
      }
    }
  }

  .btnContainer {
    display: flex;
    column-gap: 12px;

    .btn {
      max-height: 39px;
    }
    
    .help {
      width: 120px;
    }

    p {
      color: rgba($dark, 0.4);
      justify-self: flex-start;
    }
    a {
      color: $info;
      padding-left: 10px;
      text-decoration: none;
    }
  }
}
