@import '/src/style/colors.scss';

.stamp {
  display: grid;
  width: 132px;
  grid-template-columns: 30px auto;
  column-gap: 10px;
  grid-template-rows: auto 20px;
  align-items: center;
  margin-left: auto;

  height: 100%;
  padding: 10px 8px;
  border-radius: 12px;

  box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.12), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
    0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.12);

  .icon {
    font-size: 24px;
  }

  .amount {
    display: flex;
    grid-column: 2/3;
    line-height: 24px;
    column-gap: 4px;
    font-size: 16px;
    font-weight: 500;
    color: $dark;

    span {
      line-height: 24px;
      font-size: 16px;
    }
  }

  p {
    grid-column: 2/3;
    font-size: 12px;
    line-height: 16px;
    color: rgba($dark, 0.4);
  }
}

.created {
  background-color: mix($dark, #fff, 4%);

  .icon {
    color: rgba($dark, 0.24);
  }
}

.canceled,
.responded_error,
.rejected {
  background-color: mix($warning, #fff, 12%);

  .icon {
    color: rgba($warning, 0.8);
  }
}

.received,
.invited,
.assigned {
  background-color: mix($info, #fff, 12%);

  .icon {
    color: rgba($info, 0.8);
  }
}

.paid,
.responded_ok,
.delivered {
  background-color: mix($positive, #fff, 12%);

  .icon {
    color: rgba($positive, 0.8);
  }
}

.accepted,
.responded_warning,
.started,
.sent {
  background-color: mix($attention, #fff, 12%);

  .icon {
    color: rgba($attention, 0.8);
  }
}
