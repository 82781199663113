@import '/src/style/breakpoints.scss';

.filler {
  height: 50px;
}

.footer {
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  column-gap: 10px;

  @media (max-width: $tablet-small) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
