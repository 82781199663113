@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.page {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.body {
  padding: 20px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  @media  (max-width: $tablet-large) {
    grid-template-columns: 1fr;
  }
}
