@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';


.searchWrapper {
  display: grid;
  align-items: flex-start;
  column-gap: 16px;
  row-gap: 8px;
  grid-template-columns: 2fr 1fr 1fr;

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 1fr;
  }

  @media (min-width: $tablet-small) and (max-width: $tablet-large) {
    .searchField {
      grid-column: 1/3;
    }

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
} 

.searchAndToogle {
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  width: 100%;

  .searchField {
    width: 100%;
  }

  .toogle {
    // caused by empty help text being rendered
    margin-bottom: 8px;
  }

  @media (max-width: $tablet-small) {
    flex-direction: column;
  }

  @media (min-width: $tablet-small) and (max-width: $tablet-large) {
    grid-column: 1/3;
  }
}

.searchDescr {
  max-height: 10px;
}
