@import '../../style/colors.scss';
@import '../../style/breakpoints.scss';

.page {
  display: flex;
  flex-direction: column;
  background: $background-dark;
}

.body {
  background-color: $background-dark;
  padding: 20px 40px;
  @media (max-width: $tablet-small) {
    padding: 20px;
  }
}

.card {
  max-width: 785px !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}


.description {
  background-color: mix($positive, #fff, 8%);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 12px 20px;

  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: flex-start;

  color: rgba($dark, 0.8);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  .descrIcon {
    padding-right: 16px;
    font-size: 25px;
    color: $positive;
  }
}

.divider {
  margin: 20px 0 !important;
}

.icon {
  font-size: 40px !important;
  color: rgba(0, 191, 166, 0.4);
}

.validateBtn {
  margin-left: auto;
  // align-self: flex-end;
}