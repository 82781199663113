@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.page {
  width: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.body {
  background-color: $background-dark;
  padding: 20px 40px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet-small) {
    padding: 20px;
  }
}
