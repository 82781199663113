@import '../../style/colors.scss';
@import '../../style/breakpoints.scss';

.body {
  background-color: $background-dark;
  padding: 20px 40px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet-small) {
    padding: 20px;
  }
}

.page {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none !important;
}
