@import '/src/style/breakpoints.scss';

.rights {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  // padding: 10px 40px;
}
