@import '/src/style/colors.scss';

.list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.skeletonRow {
  padding: 20px;
  border-radius: 16px;
  background-color: rgba($dark, 0.1);
  transform: translate3d(0px, 0px, 0px);
  position: relative;
  overflow: hidden;


  &:nth-child(2), &:nth-child(4) {
    opacity: 0.4;
  }


  & > div {
    transform: translate3d(0px, 0px, 0px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba($dark, 0) 0%,
      rgba($dark, 0.05) 20%,
      rgba($dark, 0.05) 40%,
      rgba($dark, 0) 80%
    );
    animation: bg-move 1.5s -0.75s ease infinite;
  }
  
}

@keyframes bg-move {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
