.cardBody {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.checkboxWrapper {
  padding-left: 11px;
}

.apiTokenField {
  min-height: 150px !important;
}

.apiToken {
  height: 200px;
}

.hidden {
  display: none !important;
}
