@import '/node_modules/@sis-lab/web-ui-components/dist/style.css';
@import '/src/style/breakpoints.scss';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  // font-family: 'Roboto Flex', sans-serif;
}

p,
h1,
h2,
h3,
h4,
small,
a {
  padding: 0;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (max-width: $tablet-large) {
  .ant-scrolling-effect {
    min-width: 100% !important;
    header:first-of-type {
      margin-right: 17px;
    }
  }
}

@media (min-width: $tablet-large) {
  .ant-scrolling-effect {
    min-width: 100% !important;
    padding-right: 17px !important;
    // background-color: rgba($positive, 0.3) !important;
    header:first-of-type {
      padding-right: 37px;
    }
  }
}
