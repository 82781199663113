.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  height: 100%;
  
  img {
    margin-top: 10%;
    max-width: 250px;
    max-height: 200px;
  }
}
