@import '/src/style/mixins.scss';
@import '/src/style/colors.scss';


.noPayerRow {
  @include participantRow;
  background-color: mix($dark, #fff, 4%);
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  .label {
    font-size: 16px;
    color: rgba($dark, 0.8);
    padding-bottom: 5px;
  }

  .caption {
    font-size: 12px;
    color: rgba($dark, 0.4);
  }

  .icon {
    color: rgba($warning, 0.8);
    padding: 12px;
    font-size: 30px;
    border-radius: 50%;
    background-color: mix($warning, #fff, 20%);
  }
}
