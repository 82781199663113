@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.row {
  display: grid;
  grid-template-columns: 50px 1fr;
  column-gap: 10px;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease;

  padding: 15px 20px;
  border-radius: 16px;
  color: rgba($dark, 0.8) !important;
  box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.12), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
    0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.12);

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.4), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
      0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.4);
  }

  p,
  h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
