@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.signupButton {
  margin-top: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

// .nameRow {
//   display: grid;
//   row-gap: 10px;

//   @media (min-width: $tablet-small) {
//     grid-template-columns: 1fr 1fr;
//     column-gap: 10px;
//   }
// }

.termsBox {
  align-self: center;
  color: rgba($dark, 0.8);
  justify-self: center;
  text-align: center;
  width: 70%;
  font-size: 14px !important;

  @media (max-width: $tablet-small) {
    width: 100%;
  }
}

.business {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;

  @media (max-width: $tablet-small) {
    display: flex;
    flex-direction: column;
  }

  > :nth-child(3) {
    grid-column: 1/3;
  }
}
