@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 21px 40px;
  min-height: 82px;
  background-color: $background-light;
  box-shadow: 0px 0.5px 0px mix($dark, #fff, 12%), inset 0px -0.5px 0px mix($dark, #fff, 12%);
  gap: 10px;

  @media (max-width: $tablet-small) {
    padding: 24px 20px;
  }

  .title {
    display: flex;
    align-items: center;

    h1 {
      color: rgba($dark, 0.9);
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin: 0;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .children {
    display: flex;
    gap: 10px;
    flex-direction: row;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
