@import '/src/style//breakpoints.scss';
@import '/src/style/colors.scss';

.card {
  header {
    height: 57px;
    align-items: center;
  }
}

.docInfo {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;

  @media (min-width: $tablet-small) {
    & > *:nth-last-child(-n + 2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
  }
}

.hidden {
  display: none !important;
}
