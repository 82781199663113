@import 'src/style/breakpoints.scss';
@import 'src/style/colors.scss';

.inputsLayout {
  display: grid;
  row-gap: 10px;
}

.avatarRow {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  align-items: center;

  .avatar {
    justify-self: center;
  }

  > div {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 2fr 1fr;

    @media (max-width: $tablet-small) {
      grid-template-columns: 4fr 1fr;
    }
  }

  @media (min-width: $tablet-small) {
    grid-template-columns: 20px auto;
    grid-template-rows: 1fr;
    column-gap: 40px;
    padding-left: 30px;
  }
}

.birthRow {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;

  @media (min-width: $tablet-small) {
    grid-template-columns: 2fr 4fr;
    column-gap: 10px;
  }
}

.emailRow {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;

  @media (min-width: $tablet-small) {
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }
}
