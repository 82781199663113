@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.tabCard {
  display: flex;
  position: relative;

  .tabHeader {
    transform: translateY(-10px);
    box-shadow: 0px 0.5px 0px rgba(30, 39, 50, 0.12),
      inset 0px -0.5px 0px rgba(30, 39, 50, 0.12);

    @media (max-width: $tablet-small) {
      overflow-x: scroll;
      * {
        padding-left: 0;
        padding-right: 5px;
      }
    }

    &.rejectHeader {
      >:first-child {
        color: $warning;
      }
    }
  }

  .tabBody {
    gap: 10px;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
  }

  .inviteBtn {
    font-weight: 500;
    position: absolute;
    right: 17px;
    top: 10px;
    z-index: 2;
  }

  .hidden {
    display: none;
  }
}
