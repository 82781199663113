@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';

.tokenTab {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;

  @media (min-width: $laptop) {
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
}

.addInput {
  @media (min-width: $laptop) {
    grid-column: 1/3;
  }
}

.addInputBody {
  font-size: 16px !important;
  font-weight: 400;
}

.icon {
  font-size: 20px;
}

.addButton {
  height: 40px;
  white-space: nowrap;
}

.createNewCard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(grey, $alpha: 0.4);
  }
}

.mobile {
  @media (min-width: $tablet-small) {
    display: none;
  }
}

.notMobile {
  @media (max-width: $tablet-small) {
    display: none;
  }
}

.noTokens {
  grid-column: 1/3;
  text-align: center;
  margin-top: 200px;
}
