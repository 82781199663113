@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';

.wrapper {
  background-color: white !important;
  padding: 20px 40px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet-small) {
    padding: 20px;
  }
}

.search {
  display: grid;
  grid-template-columns: 2fr 1fr 50px;
  column-gap: 10px;

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.loadMore {
  margin: auto;
}

.disabled {
  td {
    background-color: mix($dark, #fff, 4%) !important;
    img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }
}
