@import '/src/style/colors.scss';

.title {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-left: 8px;
  height: 60px;
  max-width: 90%;

  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba($dark, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba($dark, 0.4);
  }
}
