@import '../../style/breakpoints.scss';
@import '../../style/colors.scss';

.body {
  padding: 20px 40px;
  background-color: $background-dark;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  @media (max-width: $laptop) {
    grid-template-columns: 1fr;
  }

  @media (max-width: $tablet-small) {
    padding: 20px;
  }

  .column {
    display: grid;
    grid-template-rows: auto auto;
    align-content: flex-start;
    row-gap: 20px;
  }
}

.spinner {
  margin: auto !important;
  padding-top: 200px !important;
}
