@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.modalHeader {
  display: grid;
  grid-template-columns: 45px 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
  }

  .icon {
    color: rgba($dark, 0.8);
    font-size: 45px !important;

    margin-right: auto;
    grid-row: 1/3;
    grid-column: 1/2;

    @media (max-width: $tablet-small) {
      display: none;
    }
  }

  .icon {
    font-size: 28px;
  }

  h3 {
    color: rgba($dark, 0.8);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .active {
    color: rgba($positive, 0.8);
  }

  .disabled {
    color: rgba($warning, 0.8);
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.modalFooter {
  margin-top: 10px;
  display: flex;
  column-gap: 10px;
  :first-child {
    margin-right: auto;
  }
}
