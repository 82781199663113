@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';

.searchWrapper {
  display: grid;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  grid-template-columns: 2fr 1fr 1fr;

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  @media (min-width: $tablet-small) and (max-width: $tablet-large) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
} 

.search {
  @media (min-width: $tablet-small) and (max-width: $tablet-large) {
    grid-column: 1/3;
  }
}

.searchDescr {
  max-height: 10px;
}

