@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;

  display: grid;
  row-gap: 20px;
}

.footer {
  display: grid !important;
  grid-template-columns: 1fr;
}
