@import '../../style//colors.scss';

.hasCounter {
  > :nth-child(1) {
    border: solid rgba($dark, 0.1) 1px;
    background-color: transparent;
    color: rgba($dark, 0.8);
    grid-row: 1/3;
  }
}

.signed {
  background-color: mix($positive, #fff, 8%);
}

.rejected {
  background-color: mix($warning, #fff, 8%);
}

.created,
.invited {
  background-color: mix($attention, #fff, 8%);

  .icon {
    color: rgba($dark, 0.7);
  }
}

.unsigned {
  background-color: mix($attention, #fff, 8%);
}

.stamp {
  width: 100%;
  padding: 10px;
  white-space: nowrap;
  border-radius: 8px;

  display: grid;
  grid-template-columns: 45px auto;
  grid-template-rows: 1fr 1fr;
  column-gap: 10px;
  align-items: center;

  box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.12), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
    0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.12);

  .icon {
    grid-row: 1/3;
    font-size: 27px;
    align-self: center;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba($dark, 0.8);
  }

  small {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba($dark, 0.4);
  }
}

.revoke {
  display: grid;
  grid-template-columns: 1fr 20px 20px;
  gap: 5px;
  padding-right: 10px;

  > :not(:first-child):hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
