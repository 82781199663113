@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;

  row-gap: 20px;
  justify-items: center;

  .cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 200px;
      height: 200px;
    }

    p {
      text-align: center;
    }

    small {
      width: 90%;
      text-align: center;
      color: rgba($dark, 0.6);

      @media (max-width: $tablet-small) {
        margin-top: 20px;
      }
    }
  }

  .footer {
    display: grid !important;
    grid-template-columns: 1fr;
  }
}
