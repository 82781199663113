@import '/src/style/breakpoints.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;
  display: grid;
  row-gap: 10px;
}

.header {
  text-align: center;
  h2 {
    margin-bottom: 14px;
  }

  a {
    padding-left: 12px;
  }
}

.spin {
  margin: auto;
}

.error {
  margin: auto;
}

.button {
  @media (min-width: $tablet-small) {
    justify-self: flex-end;
  }
}
