@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.page {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  div {
    padding-top: 110px;
    display: flex;
    align-items: center;
    column-gap: 20px;

    img {
      width: 50px;
    }

    h3 {
      font-size: 48px;
    }

    @media (max-width: $tablet-small) {
      img {
        display: none;
      }

      p {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

.loader {
  --uib-size: 280px;
  --uib-speed: 1.4s;
  --uib-color: black;
  --uib-line-weight: 7px;

  @media (max-width: $tablet-small) {
    --uib-size: 180px;
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uib-line-weight);
  width: var(--uib-size);
  border-radius: calc(var(--uib-line-weight) / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $positive;
  opacity: 0.1;
}

.loader::after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: calc(var(--uib-line-weight) / 2);
  animation: raceBy var(--uib-speed) ease-in-out infinite;
  transform: translateX(-100%);
  background-color: $positive;
}

@keyframes raceBy {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
