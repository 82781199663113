$attention: #ffcc00;
$info: #007aff;
$positive: #00bfa6;
$green: #00bfa6;
$warning: #ff3b30;
$dark: #1e2732;
$light: #ffffff;

$background-light: mix($positive, #fff, 2%);
$background-dark: mix($positive, #fff, 4%);
$positive-gradient: linear-gradient(135deg, #00bfa6 0%, #06a18f 100%);
