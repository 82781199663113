@import '/src/style/breakpoints.scss';

.peppolTab {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  row-gap: 16px;

  @media (min-width: $laptop) {
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }

  .column {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
