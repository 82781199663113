@import '/src/style/colors.scss';

.card {
  header {
    height: 57px;
    align-items: center;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.hidden {
  display: none !important;
}

.noPayer {
  text-align: center;
  font-size: 16px;
  color: rgba($dark, 0.6);
  padding: 10px 30px;
}
