@import '/src/style/colors.scss';

.typeBox {
  display: flex;
  flex-direction: column;
  width: 180px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  border: solid 1px rgba($dark, 0.4);
  color: mix($dark, #fff, 40%);
  padding: 5px 15px 15px 15px;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  p {
    font-weight: 500;
    text-align: center;
  }

  &.selected {
    border: solid 1px $positive;
    color: mix($positive, #fff, 90%);
  }

  &:not(.selected) > img {
    filter: grayscale(1);
  }

  img {
    margin-bottom: 10px;
    width: 60px;
  }
}

.check {
  align-self: flex-end;
  background-color: mix($positive, #fff, 20%);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 3px 5px;
}

button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
}
