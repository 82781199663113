@import '/src/style/colors.scss';

.card {
  max-width: 700px !important;
  overflow: hidden;
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.hidden {
  display: none !important;
}

.button {
  align-self: flex-end;
}
