@import '/src/style/breakpoints.scss';

.title {
  margin-left: 15px;
}

.footer {
  display: flex;
  align-items: center;
  width: 100%;

  .checkbox {
    margin-right: auto;
  }

  @media (max-width: $tablet-small) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    .checkbox {
      margin-left: 20px;
      grid-column: 1/3;
    }
  }
}

.form {
  display: grid;
  row-gap: 10px;

  .amountRow {
    display: grid;
    grid-template-columns: 160px auto;
    column-gap: 20px;

    @media (max-width: $tablet-small) {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .secondRow {
    display: grid;
    grid-template-columns: auto 260px;
    column-gap: 20px;

    @media (max-width: $tablet-small) {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
}

.modal {
  width: 800px !important;
}

.hidden {
  display: none !important;
}

.payer {
  display: grid;
  grid-template-columns: 1fr 1fr;

  row-gap: 10px;
  column-gap: 20px;

  .email {
    grid-column: 1/3;
  }

  @media (max-width: $tablet-small) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.other {
  display: grid;
  grid-template-columns: 1fr 1fr;

  row-gap: 10px;
  column-gap: 20px;

  @media (max-width: $tablet-small) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.document {
  display: grid;
  grid-template-columns: 1fr 1fr;

  row-gap: 10px;
  column-gap: 20px;

  .title {
    margin-left: 0;
    grid-column: 1/3;
  }

  @media (max-width: $tablet-small) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
