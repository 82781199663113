@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';

.certificateCard {
  background: $positive-gradient;
  overflow: hidden;
  text-overflow: ellipsis;

  li, span, header {
    color: white !important;
    border-color: rgba(#fff, 0.2) !important;
    border-width: 2px !important;
  }
  ul {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;

    li {
      padding: 8px;
      border-radius: 8px;
      background: rgba(30, 39, 50, 0.08);

      display: grid;
      grid-template-columns: 20px auto;
      column-gap: 10px;
      align-items: flex-start;

      .certIcon {
        font-size: 20px;
      }
    }
    .infoCert {
      background-color: transparent;
    }
  }
}

.download {
  display: flex;
  justify-content: flex-end;

  @media (min-width: $tablet-small) {
    width: 40%;
    margin-left: auto;
  }

  .downloadBtn {
    @media (max-width: $tablet-small) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
