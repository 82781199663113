@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;

  header {
    justify-content: center;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
  }

  p {
    font-weight: 500;
  }

  span {
    width: 90%;
    text-align: center;
    color: rgba($dark, 0.6);

    @media (max-width: $tablet-small) {
      margin-top: 20px;
    }
  }
}

.footer {
  display: grid !important;
  grid-template-columns: 1fr;
}
