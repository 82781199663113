@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;
}

.cardBody {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.typeSelector {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.business {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;

  @media (max-width: $tablet-small) {
    display: flex;
    flex-direction: column;
  }

  > :nth-child(3) {
    grid-column: 1/3;
  }
}

.hidden {
  display: none !important;
}

.header {
  text-align: center;
  margin: 10px 0px;

  h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  a {
    padding-left: 10px;
  }
}

.businessHelp {
  padding: 20px 0;
  text-decoration: underline;
  text-align: center;
}
