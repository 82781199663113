@import '../../../../style/colors.scss';

.title {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  padding-left: 16px;
}

.header {
  display: flex;
  align-items: center;
}

.divider {
  margin: 16px 0;
}

.hidden {
  display: none !important;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 12px;
  background-color: $warning;
  font-size: 12px;
  line-height: 16px;
  color: white;
  min-height: 24px;
  min-width: 24px;
  span {
    margin: 0 4.5px;
  }
}
