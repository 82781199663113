@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';

.tokenCard {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;
  white-space: nowrap;

  @media (min-width: $tablet-small) {
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;

    :nth-child(1) {
      grid-column: 1/3;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  font-size: 20px;
}
