@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.statusField {
  color: rgba($dark, 0.8);
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;

  .header {
    display: flex;
    justify-content: space-between;
    height: 24px;

    color: rgba($dark, 0.8);
    font-weight: 500;
    padding: 0 16px 8px 16px;
    font-size: 12px;
    line-height: 16px;

    .tooltip {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .input {
    display: flex;
    border-radius: 8px;
    padding: 8px 11px;
    font-size: 16px;
    color: rgba($dark, 0.8);
    font-weight: 500;
    line-height: 24px;
    background-color: mix($dark, #fff, 4%);

    input {
      width: 100%;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background-color: mix($dark, #fff, 4%);
    }

    .prefix {
      padding-right: 5px;
      margin-right: 5px;
      padding-right: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba($dark, 0.4);
      border-right: 2px solid rgba($dark, 0.2);

      @media (max-width: $tablet-small) {
        display: none;
      }
    }

    .description {
      padding-left: 11px;
      font-size: 14px;
      margin: auto;
      color: rgba($dark, 0.4);
      font-weight: 400;
    }
  }
}

.responded_warning,
.accepted,
.started,
.sent {
  background-color: mix($attention, #fff, 12%) !important;

  input {
    background-color: mix($attention, #fff, 12%) !important;
  }

  .prefix {
    color: rgba($attention, 0.8) !important;
    border-right: 2px solid rgba($attention, 0.2) !important;
  }
}

.responded_error,
.canceled,
.rejected {
  background-color: mix($warning, #fff, 12%) !important;

  input {
    background-color: mix($warning, #fff, 12%) !important;
  }

  .prefix {
    color: rgba($warning, 0.8) !important;
    border-right: 2px solid rgba($warning, 0.2) !important;
  }
}

.responded_ok,
.paid {
  background-color: mix($positive, #fff, 12%) !important;

  input {
    background-color: mix($positive, #fff, 12%) !important;
  }

  .prefix {
    color: rgba($positive, 0.8) !important;
    border-right: 2px solid rgba($positive, 0.2) !important;
  }
}

.invited,
.assigned {
  background-color: mix($info, #fff, 12%) !important;

  input {
    background-color: mix($info, #fff, 12%) !important;
  }

  .prefix {
    color: rgba($info, 0.8) !important;
    border-right: 2px solid rgba($info, 0.2) !important;
  }
}
