@import '/src/style/mixins.scss';

.card {
  @include cardPage;
}

.cardBody {
  display: flex;
  justify-content: center;
}

.header {
  text-align: center;
  h2 {
    margin-bottom: 14px;
  }
}
