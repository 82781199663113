@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';


.inviteeRow {
  @include participantRow;
  background-color: mix($info, #fff, 12%);

  .icon {
    height: 40px !important;
    font-size: 24px !important;
    padding: 8px;
    border-radius: 50%;
    background-color: mix($info, #fff, 20%);
    color: mix($info, #fff, 80%) !important;
    box-shadow: 0px 0px 0px 0.5px mix($info, #fff, 12%) inset, 0px 0px 0px 0.5px mix($info, #fff, 12%);
  }
}
