@import 'src/style/colors.scss';
@import 'src/style/breakpoints.scss';

.footer {
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;

  @media (min-width: $tablet-small) {
    display: flex;
  }

  @media (max-width: $tablet-small) {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr 1fr;
  }

  .jwtWrapper {
    background-color: black;

    @media (min-width: $tablet-small) {
      margin-right: auto;
    }  

    @media (max-width: $tablet-small) {
      grid-column: 1/3;
    }

    .jwtButton {
      color: white;
      font-weight: 500;
      display: flex;
      align-items: center;
    
      img {
        height: 40px;
        height: 40px;
      }
    }
  }

  >:nth-child(2), >:nth-child(3) {
    margin: 0 !important;
    height: 80%;
  } 
}
