@import '/src/style/colors.scss';


.titleColumn {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-left: 5px;

  h3 {
    color: rgba($dark, 0.8);
    font-size: 16px;
    line-height: 24px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    color: rgba($dark, 0.4);
    font-size: 12px;
    line-height: 16px;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.noPayer > h3  {
    color: rgba($dark, 0.4);
  }
}

.payeeColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h3 {
    color: rgba($dark, 0.8);
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;

    margin-bottom: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    color: rgba($dark, 0.4);
    font-size: 12px;
    line-height: 16px;
  }

  &.noPayer > h3  {
    color: rgba($dark, 0.4);
  }
}
