@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';

.card {
  header {
    height: 57px;
    align-items: center;
  }
}

.prmInfo {
  display: grid;
  column-gap: 10px;
  row-gap: 5px;
  grid-template-columns: minmax(1px, 100%);

  .amountRow {
    display: grid;
    grid-template-columns: 250px 1fr;
    column-gap: 10px;

    @media (max-width: $tablet-small) {
      grid-template-columns: 1fr;
    }
  }
}

.currency {
  background-color: mix($dark, #fff, 80%) !important;
  color: white;

  & > * {
    color: white;
  }
}

.hidden {
  display: none !important;
}
