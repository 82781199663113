@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.stamp {
  display: grid;
  grid-template-columns: 125px auto;
  grid-template-rows: 1fr;
  column-gap: 8px;
  color: mix($dark, #fff, 40%);

  padding: 8px;
  border-radius: 8px;

  .icon {
    font-size: 24px;
    transform: translateY(-7px);
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.dot {
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 0.5px mix($dark, #fff, 12%) inset, 0px 0px 0px 0.5px mix($dark, #fff, 12%);
}

.received,
.responded_warning,
.sent {
  background-color: mix($attention, #fff, 20%);
  color: mix($attention, #fff, 80%) !important;
}

.responded_ok,
.paid {
  background-color: mix($positive, #fff, 20%);
  color: mix($positive, #fff, 80%);
}

.started,
.accepted,
.delivered {
  background-color: mix($positive, #fff, 10%);
  color: mix($positive, #fff, 60%);
}

.responded_error,
.rejected,
.canceled {
  background-color: mix($warning, #fff, 20%);
  color: mix($warning, #fff, 80%);
}

.log-ERROR {
  background-color: mix($warning, #fff, 20%);
}

.invited,
.assigned {
  background-color: mix($info, #fff, 20%) !important;
  color: mix($info, #fff, 80%) !important;
}

.created,
.log-OK {
  background-color: white !important;
}
