.cardBody {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.cardHeader {
  height: 36px;
  align-items: start;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .headerSuffix {
    display: flex;
    column-gap: 10px;
    transform: translateY(-12px);
  }

  .loadingButton {
    @keyframes rotateAnimation {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
      }
    }
    span {
      animation: rotateAnimation 0.8s linear infinite;
    }
  }
}

.footer {
  display: flex !important;
}

.hidden {
  display: none !important;
}
.textArea {
  resize: vertical !important;
}
