@import '/src/style/mixins.scss';

.row {
  @include card;
  display: grid;
  grid-template-columns: 40px auto 200px;
  height: 84px;
  column-gap: 20px;
  white-space: normal;
  align-items: center;
  text-overflow: ellipsis;

  @media (max-width: $tablet-small) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: fit-content;

    > :nth-child(1) {
      display: none;
    }

    > :nth-child(2) {
      margin-left: 20px;
    }

    > :nth-child(3) {
      grid-column: 1/3;
    }
  }

  > :nth-child(2) {
    display: flex;
    flex-direction: column;
  }

  .nameColumn {
    >:nth-child(2):hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
