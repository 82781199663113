.card {
  overflow: hidden !important;
}

.documentList {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  row-gap: 14px;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
}

.hidden {
  display: none !important;
}
