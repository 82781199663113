.form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-top: 5px;
}

.hidden {
  display: none !important;
}
