.lang {
  div {
    width: fit-content;
    white-space: nowrap;
    div > span {
      font-size: 16px !important;
  
      &:last-of-type {
        padding-left: 10px;
      }
    }
  }
}
