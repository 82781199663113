@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.modalHeader {
  display: flex;
  column-gap: 20px;
  color: rgba($dark, 0.8);

  .icon {
    font-size: 25px !important;

    @media (max-width: $tablet-small) {
      display: none;
    }
  }

  h3 {
    color: rgba($dark, 0.8);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.sealModal > div {
  height: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modalFooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media (min-width: $tablet-small) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: -1 !important;
}

.hidden {
  display: none !important;
}

.error {
  padding-top: 10px;
  color: rgba($warning, 0.8) !important;
}
