@import '/src/style/colors.scss';

.counter {
  padding: 2px 8px;
  border-radius: 8px;
  background-color: mix($dark, #fff, 10%);
}

.counterSigned {
  padding: 2px 8px;
  border-radius: 8px;
  background-color: mix($positive, #fff, 20%);
}
