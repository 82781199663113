@import './breakpoints.scss';

@mixin cardPage {
  margin: auto;
  margin-top: 50px;

  @media (min-width: $tablet-small) {
    max-width: 600px;
  }

  @media (max-width: $tablet-small) {
    margin: 20 40px;
    max-width: 90%;
  }
}

@mixin card {
  gap: 10px;
  padding: 8px 16px;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 0.5px rgba(30, 39, 50, 0.12), inset 0px 0px 0px 0.5px rgba(30, 39, 50, 0.12);
}

@mixin participantRow {
  @include card;
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 20px;
  height: 84px;
  white-space: normal;
  align-items: center;
  text-overflow: ellipsis;

  @media (max-width: $tablet-small) {
    padding: 20px;

    > :nth-child(3) {
      grid-column: 1/3;
    }
  }

  > :nth-child(2) {
    display: flex;
    flex-direction: column;
  }
}
