@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

@mixin column() {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-left: 8px;

  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba($dark, 0.8);
    text-overflow: ellipsis;
  }
  
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba($dark, 0.4);

  }
}

.avatar {
  padding-left: 10px;
}

.title {
  @include column();
}

.position {
  @include column();
  text-align: end;
  h3 {
    justify-self: flex-start;
  }
}

.role {
  width: 100%;
  display: flex;
  justify-content: center;

  .tag {
    width: fit-content;
    border: 1px solid rgba($dark, 0.1);
    border-radius: 16px;
    padding: 2px 5px;
    font-size: 16px;

    &.admin {
      background-color: mix($warning, #fff, 80%) !important;
    }
    &.owner {
      background-color: mix($positive, #fff, 60%) !important;
    }
    &.member {
      display: none !important;
    }
  }
  
}


