@import '/src/style/colors.scss';

.modalFooter {
  display: flex;
}

.icon {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.textArea {
  height: 100%;
  background-color: mix($info, #fff, 8%) !important;
}

.textAreaWrapper {
  height: 240px;
}
