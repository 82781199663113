@import '/src/style/colors.scss';

.tableFooter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;


  font-weight: 500;
  color: rgba($dark, 0.8) !important;
  font-size: 16px;
  line-height: 24px;

  >:nth-child(2) {
    margin-left: auto;
  }
}

.hidden {
  display: none;
}
