@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

@mixin column() {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-left: 8px;
  height: 50px;

  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba($dark, 0.8);
    text-overflow: ellipsis;
  }
  
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba($dark, 0.4);

  }
}

.title {
  @include column();
}
