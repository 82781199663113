@import '/src/style/colors.scss';

.wrap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.addLine {
  display: grid;
  grid-template-columns: calc(100% - 42px) 42px;
  column-gap: 10px;
  margin-bottom: -26px;
  height: 90px;

  &.error {
    margin-bottom: 0px;
  }

  button {
    margin: auto;
  }
}

.select {
  input[id^='react-select-'] {
    opacity: 1 !important;
  }
  div[class$='-singleValue'] {
    // opacity: 0 !important;
    display: none;
  }
}

.list {
  margin: 0px 00px;
  padding: 10px 10px;
  padding-bottom: 5px;
  border-radius: 8px;

  box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.12), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
    0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.12);

  text-align: center;
  .clear {
    text-decoration: underline;
    color: $info;

    &:hover {
      cursor: pointer;
    }
  }
}
