@import '/src/style/colors.scss';

.row {
  display: flex;
  box-sizing:border-box;
  justify-content: space-between;
  overflow: hidden;
  // width: 100%;
  align-items: center;
  padding: 10px 20px;
  border-radius: 16px;
  max-width: 100%;
  color: rgba($dark, 85%);
  box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.12), 0px 4px 4px rgba($dark, 0),
    0px 1px 8px rgba($dark, 0), 0px 2px 4px rgba($dark, 0),
    0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.12);

  .titleWrap {
    display: flex;
    column-gap: 10px;
    width: calc(100% - 50px);
    align-items: center;
  }

  p {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  button:hover {
    transform: scale(1.1);
  }

  &:hover, &.selected {
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.4), 0px 4px 4px rgba($dark, 0),
    0px 1px 8px rgba($dark, 0), 0px 2px 4px rgba($dark, 0),
    0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.4);
  }
}

.completed {
  background-color: mix($positive, #fff, 5%);
  span {
    padding: 5px;
    font-size: 20px;
    margin-right: 5px;
  }

  &:hover, &.selected {
    box-shadow: 0px 0px 0px 0.5px rgba($positive, 0.6), 0px 4px 4px rgba($positive, 0),
    0px 1px 8px rgba($positive, 0), 0px 2px 4px rgba($positive, 0),
    0px 0px 0px 4px rgba($positive, 0), inset 0px 0px 0px 0.5px rgba($positive, 0.6);
  }
}


.error {
  background-color: mix($warning, #fff, 5%);

  h3 {
    color: rgba($dark, 100%);
  }
  button {
    background-color: transparent !important;
    &:hover {
      transform: scale(1.1);
    }
  }

  &:hover, &.selected {
    box-shadow: 0px 0px 0px 0.5px rgba($warning, 0.6), 0px 4px 4px rgba($warning, 0),
    0px 1px 8px rgba($warning, 0), 0px 2px 4px rgba($warning, 0),
    0px 0px 0px 4px rgba($warning, 0), inset 0px 0px 0px 0.5px rgba($warning, 0.6);
  }
}

.errorMsg {
  color: rgba($warning, 80%);
  padding-left: 20px;
}