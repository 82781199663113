@import '../../../../style/colors.scss';

.sider {
  flex-shrink: 0;
}

.profile {
  margin-top: auto;
  box-shadow: none !important;

  &:hover {
    box-shadow: none;
  }

  // *:nth-child(2) {
  // transform: translateX(-10px);
  // }

  .avatar {
    border-radius: 50%;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 12px;
  background-color: $warning;
  font-size: 12px;
  line-height: 16px;
  color: white;
  min-height: 24px;
  min-width: 24px;
  span {
    margin: 0 4.5px;
  }
}
