@import '/src/style/breakpoints.scss';

.credentialsTab {
  // display: grid;
  // grid-template-columns: 1fr;
  height: 100%;
  width: 60%;
  margin: 0 auto;
  // row-gap: 16px;

  // @media (min-width: $laptop) {
  //   grid-template-columns: 1fr 1fr;
  //   column-gap: 16px;
  // }
}
