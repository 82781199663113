.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.row {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
