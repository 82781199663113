@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;
  p {
    color: rgba($dark, 1);
  }
  header > span {
    width: 100%;
    text-align: center;
  }


}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
}


.eu {
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  row-gap: 10px;
  column-gap: 20px;

  >:nth-child(1) {
    margin-left: auto;
  }

  >:nth-child(2) {
    margin-right: auto;
  }


  >:nth-child(3) {
   grid-column: 1/3;
  }
}

.idenfy {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn {
  width: 80%;
}
