@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.formContainer {
  display: flex;
  flex-direction: column;
}

.generalInfoRow {
  display: flex;
  flex-direction: row;

  @media (max-width: $tablet-small) {
    flex-direction: column;
    align-items: center;
  }
}

.generalInfoValues {
  flex-grow: 2;
  margin-left: 20px;
  display: grid;
  gap: 12px;
  width: 100%;


  @media (min-width: $tablet-small) {
    :nth-child(n) {
      grid-column: 1/6;
    }
  
    :nth-child(2) {
      grid-column: 1/2;
    }
  
    :nth-child(3) {
      grid-column: 2/6;
    }
  }
}

.companyValues {
  display: grid;
  gap: 12px;

  @media (min-width: $tablet-small) {
    :nth-child(n) {
      grid-column: 1/6;
    }

    :nth-child(3) {
      grid-column: 1/5;
    }

    :nth-child(4) {
      grid-column: 5/6;
    }
  }
}

.certificateValues {
  display: grid;
  gap: 12px;

  @media (min-width: $tablet-small) {
    :nth-child(n) {
      grid-column: 1/6;
    }

    :nth-child(2) {
      grid-column: 1/3;
    }

    :nth-child(3) {
      grid-column: 3/6;
    }
  }
}

.ownerTag {
  align-items: center;
  background-color: mix($positive, #fff, 20%);
  border-radius: 12px;
  color: rgba($dark, 0.8);
  display: flex;
  font-weight: 500;
  justify-content: center;
  line-height: 16px;
  width: fit-content;
  padding: 8px;
  size: 12px;
  white-space: nowrap;
}

.hidden {
  display: none !important;
}
