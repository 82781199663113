@import '/src/style/colors.scss';

.inputWrapper {
  span {
    transition: all 0.3s ease;
    height: 24px;
  } 

  .input {
    padding: 0;
  }

  .collapse:hover {
    cursor: pointer;
    color: $dark;
  }

  &:not(.wrapperExpanded) {
    span {
      border-left: none !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  
    .input {
      margin: 0;
      width: 0px;
    }

    .collapse {
      display: none;
    }
  }
}
