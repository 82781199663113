.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loaderContainer {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
}

.loader {
  font-size: 60px !important;
}
