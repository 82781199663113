@import '/src/style/breakpoints.scss';

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: -1 !important;
  height: 400px;
}

.modalFooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media (min-width: $tablet-small) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
