@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;

  display: flex;
  row-gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
  }

  p {
    text-align: center;
  }
}
