@import '/src/style/colors.scss';

.card {
  header {
    max-height: 57px;
    align-items: center;
  }
}

.explanation {
  background: $positive-gradient;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 8px 11px;
  border-radius: 8px;
  margin-bottom: 10px;
  color: white;

  b {
    font-weight: 500;
  }
}

.hidden {
  display: none !important;
}
