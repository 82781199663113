@import '/src/style/colors.scss';

@mixin selector {
  border: mix($dark, #fff, 20%) solid 1px;
  width: fit-content;
  height: fit-content;
  position: relative;
  border-radius: 16px;
  padding: 20px;

  &.selected, &:hover {
    border: mix($positive, #fff, 80%) solid 1px;
    transform: scale(1.05);
    cursor: pointer;
  }

  .check {
    color: $positive;
    background-color: mix($positive, #fff, 20%);
    transform: scale(0.7);
    border-radius: 50%;
    padding: 2px 4px;

    position: absolute;
    right: 2px;
    top: 2px
  }

  img {
    width: 60px;
    height: 60px;
  }
}

.smart {
  @include selector();

}

.mobile {
  @include selector();
}

.idenfy {
  @include selector();

  margin: auto;
  align-self: center;
  padding: 10px 30px 5px 30px;
  margin-bottom: 10px;

  img {
    width: 100px
  }
}

.hidden {
  display: none !important;
}
