@import '/src/style/mixins.scss';

.card {
  @include cardPage;
}

.header {
  text-align: center;
  h2 {
    margin-bottom: 14px;
  }

  a {
    padding-left: 12px;
  }
}

.emailForm {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  a {
    padding-top: 10px;
    align-self: flex-end;
  }
}
