@import '/src/style/colors.scss';
@import '/src/style/breakpoints.scss';

.modalHeader {
  display: flex;
  column-gap: 20px;
  color: rgba($dark, 0.8);

  .icon {
    font-size: 45px !important;

    @media (max-width: $tablet-small) {
      display: none;
    }
  }

  h3 {
    color: rgba($dark, 0.8);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .active {
    color: rgba($positive, 0.8);
  }

  .disabled {
    color: rgba($warning, 0.8);
  }
}

.employeeModal > div {
  height: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modalFooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;

  .checkbox {
    grid-column: 1/3;
  }

  column-gap: 10px;

  @media (min-width: $tablet-small) {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .checkbox {
      margin-right: auto;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: -1 !important;
}

.hidden {
  display: none !important;
}

.notMobile {
  @media (max-width: $tablet-small) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: $tablet-small) {
    display: none !important;
  }
}

.error {
  // padding-top: 10px;
  font-weight: bold;
  color: rgba($warning, 0.8) !important;
}

.switch {
  flex-direction: row-reverse;
}

.rights {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  // justify-content: center;
  column-gap: 50px;
  row-gap: 10px;
  padding: 0 40px;

  .admin {
    margin: auto;
    grid-row: 1/4;
  }

  .switch:not(.admin) {
    justify-self: flex-start;
    width: 210px !important;
    text-align: left;
    justify-content: space-between;
  }
}
