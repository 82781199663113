@import '/src/style/colors.scss';

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 16px;
  color: rgba($dark, 0.8) !important;
  box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.12), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
    0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.12);

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 0.5px rgba($dark, 0.4), 0px 4px 4px rgba($dark, 0), 0px 1px 8px rgba($dark, 0),
      0px 2px 4px rgba($dark, 0), 0px 0px 0px 4px rgba($dark, 0), inset 0px 0px 0px 0.5px rgba($dark, 0.4);

    .title {
      font-weight: 500;
    }
  }

  .title {
    width: calc(100% - 100px);
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .date {
    color: rgba($dark, 0.5) !important;
    width: 85px;
  }

  div {
    display: flex;
    column-gap: 10px;
    align-items: center;
    // icon size
    span {
      font-size: 25px !important;
    }
  }
}
