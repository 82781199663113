.card {
  display: flex;
  flex-direction: column;
  width: fit-content;
  row-gap: 10px;
}

.switch {
  // flex-direction: row-reverse;
  padding: 10px;
  column-gap: 10px;

  p {
    width: fit-content;
  }
}
