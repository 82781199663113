@import '/src/style/breakpoints.scss';
@import '/src/style/colors.scss';
@import '/src/style/mixins.scss';

.card {
  @include cardPage;

  min-height: 400px;
  p {
    color: rgba($dark, 1);
  }

  header>:nth-child(1) {
    margin: auto 0;
    font-size: 34px;

    &:hover {
      transform: scale(1.2);
    }
  } 

  header>:nth-child(2) {
    margin: auto;
  } 
}